// NAVIGATION LINKS SETUP
const navLinks = document.querySelectorAll( '.mainMenu__link, .floatingCTA__link' )
navLinks.forEach( link => {
  link.addEventListener( 'click', ( event ) => {
    event.preventDefault()
    const targetSection = document.getElementById( link.getAttribute( 'href' ).slice( 1 ))
    smoothScrollTo( targetSection )
  })
})


// NAVIGATION LINKS STYLE ON SCROLL
function handleScroll(){
  const sections = document.querySelectorAll( 'article' )
  const navLinks = document.querySelectorAll( '.mainMenu__link' )
  const viewportHeight = window.innerHeight

  let currentSection

  sections.forEach( section => {
    const sectionTop = section.offsetTop
    const sectionHeight = section.offsetHeight
    const scrollY = window.scrollY

    if( scrollY >= sectionTop - (viewportHeight - 300) && scrollY < sectionTop + sectionHeight ){
      currentSection = section.id
    }
  })

  navLinks.forEach( link => {
    link.classList.remove( 'mainMenu__link--active' )
    if( link.getAttribute( 'href' ).slice( 1 ) === currentSection ){
      link.classList.add( 'mainMenu__link--active' )
      window.history.replaceState( {}, document.title, `#${link.getAttribute( 'href' ).slice( 1 )}` )
    }
  })
}


// SMOOTH SCROLL ON LIKS
function smoothScrollTo( targetSection ){
  const startingY = window.scrollY
  const targetY = targetSection.offsetTop
  const distance = targetY - startingY
  const duration = 700
  const targetId = targetSection.id

  let start = null

  function step( timestamp ){
    if( !start ) start = timestamp
    const progress = timestamp - start
    const easeInQuad = (t) => t * t
    const newY = startingY + easeInQuad( progress / duration ) * distance
    window.scrollTo( 0, newY )
    if( progress < duration ){
      window.requestAnimationFrame( step )
    } else {
      window.scrollTo( 0, targetY )
    }
  }
  window.requestAnimationFrame( step )
  window.history.replaceState( {}, document.title, `#${targetId}` )
}


// SCROLL LISTENER
window.addEventListener( 'scroll', handleScroll )


// PASSWORD PROTECT
// const passwordForm = document.getElementById('passwordForm')
// const passwordInput = document.getElementById('passwordForm__password')
// const hashedPassword = document.getElementById('passwordForm__hashed').value
// const passwordResult = document.getElementById('passwordForm__result')
// const loginForm = document.getElementById('login')
// const protectedContent = document.getElementById('single')

// protectedContent.style.display = "none"

// async function comparePassword( enteredPassword ){
//   const hash = new TextEncoder().encode( enteredPassword )
//   const userHash = await crypto.subtle.digest( 'SHA-256', hash )
//   const userHashArray = Array.from( new Uint8Array( userHash ))
//   const userHashString = userHashArray.map( b => b.toString( 16 ) ).join( '' )
//   console.log( userHashString + ' === ' + hashedPassword )
//   return userHashString === hashedPassword
// }

// passwordForm.addEventListener('submit', async function( event ) {
//   event.preventDefault()
//   const enteredPassword = passwordInput.value
//   const decodedPassword = atob( hashedPassword )
//   // const passwordMatch = await comparePassword( enteredPassword )

//   if( enteredPassword === decodedPassword ){
//     protectedContent.style.display = 'block'
//     passwordForm.style.display = 'none'
//     loginForm.style.display = 'none'
//   } else {
//     passwordResult.style.display = 'flex'
//   }

//   passwordInput.value = ""
// })
